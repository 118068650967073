import { Injectable, isDevMode } from '@angular/core';
import { startCase } from 'lodash';

interface UserData {
  name?: string;
  email?: string;
  userType?: string;
  country?: string;
  phoneNumber?: string;
  leadSource?: string;
  extraData?: Record<string, string>;
  hasClickedBecomeProvider?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userData: UserData = {};
  private hasCRMProfile = false;

  constructor() {
    this.userData = this.getStoredUserData();
  }

  private sendUserDataToCRM(userData: UserData): void {
    // Do not send parent data to CRM
    if (userData.userType === 'parent' || isDevMode()) {
      return;
    }
    // The keys will be converted into CRM columns titles
    // That is why the keys are capitalized and have spaces
    const splitName = userData?.name?.replace(' ', ',').split(',');
    const firstName = splitName?.[0];
    const lastName = splitName?.[1];
    const crmData = {
      Name: userData.name,
      'First Name': firstName,
      'Last Name': lastName,
      Email: userData.email,
      Country: userData.country,
      'Lead Source': userData.leadSource,
      Mobile: userData.phoneNumber ?? '',
      'User Type': userData.userType ?? '',
    };

    // Inject extra data into CRM columns
    for (const key in userData.extraData) {
      crmData[startCase(key)] = userData.extraData[key];
    }

    if (this.hasCRMProfile) {
      // Update user data to CRM
      (window as any)?.fwcrm.set(crmData);
    } else {
      // Create a new CRM profile
      const identifier = userData.email;

      if (!identifier) return;

      (window as any)?.fwcrm.identify(identifier, crmData);

      this.hasCRMProfile = true;
    }
  }

  private storeUserData(userData: UserData): void {
    localStorage.setItem('userData', JSON.stringify(userData));
  }

  private getStoredUserData(): UserData {
    const userData = localStorage.getItem('userData');

    return userData ? JSON.parse(userData) : {};
  }

  public setUser(userData: Partial<UserData>): void {
    // Set user data
    this.userData = {
      ...this.userData,
      ...userData,
    };
    this.storeUserData(userData);
    this.sendUserDataToCRM(userData);
  }

  public trackEvent(eventName: string, data?: Record<string, unknown>): void {
    // Do not send parent data to CRM
    if (this.userData.userType === 'parent' || isDevMode()) {
      return;
    }

    (window as any)?.fwcrm.trackCustomEvent(eventName, {
      ...this.userData,
      ...data,
    });
  }

  public getUser(): UserData {
    // Get user data
    return this.userData;
  }
}
